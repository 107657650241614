import React, { createContext, useContext, useState } from 'react';
import messagesAr from './messages_ar';
import messagesEn from './messages_en';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [activeComponent, setActiveComponent] = useState('');
  const messages = language === 'en' ? messagesEn : messagesAr;
  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        messages,
        changeLanguage,
        activeComponent,
        setActiveComponent,
      }}
    >
      <div dir={messages.config.direction}>{children}</div>
    </LanguageContext.Provider>
  );
};
