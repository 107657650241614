import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../l10n/LanguageContext';
import { getCookie } from '../../../services/storageUtils';
import ForgotPassword from '../../common/ForgotPassword';
import ResetPassword from '../../common/ResetPassword';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [pToken, setPToken] = useState();
  const { setActiveComponent } = useLanguage();

  const handleClick = () => {
    setStep(step + 1);
  };
  useEffect(() => {
    setActiveComponent('forgot_password');
    if (getCookie('UID')) {
      navigate('/eg/profile');
    }
  });
  return (
    <>
      {step === 0 ? (
        <ForgotPassword onBtnClick={handleClick} setPToken={setPToken} />
      ) : (
        <ResetPassword pToken={pToken} />
      )}
    </>
  );
}

export default ForgotPasswordPage;
