import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../l10n/LanguageContext';
import { getCookie } from '../../../services/storageUtils';
import ConsumerOptin from '../../common/ConsumerOptin';

const Profile = () => {
  const navigate = useNavigate();
  const { messages: M, setActiveComponent } = useLanguage();
  const username = getCookie('username') || '';

  useEffect(() => {
    setActiveComponent('profile');
    if (!getCookie('UID')) {
      navigate('/eg/login');
    }
  });
  
  return (
    <div className="profile-container">
      <div className="profile-heading">
        <h1>{`${M.msg_welcome}, ${username}!`}</h1>
        <p>{M.msg_profile_desc}</p>
      </div>
      <ConsumerOptin />
    </div>
  );
};

export default Profile;
