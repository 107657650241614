// storageUtils.js

export function setCookie(name, value, minutes) {
  const encodedValue = btoa(value);
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + encodedValue + expires + '; path=/; Secure';
}

export function getCookie(name) {
  const decodedValue =
    decodeURIComponent(
      atob(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
              encodeURIComponent(name).replace(/[-.+*]/g, '\\$&') +
              '\\s*\\=\\s*([^;]*).*$)|^.*$'
          ),
          '$1'
        )
      )
    ) || null;
  return decodedValue;
}

export function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function deleteCookies(cookies) {
  if (!Array.isArray(cookies)) {
    console.error('Parameter should be an array of cookie names.');
    return;
  }

  cookies.forEach((cookieName) => {
    document.cookie =
      cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  });
}
