import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { useLanguage } from '../../l10n/LanguageContext';

const Popup = ({ msgDetails, onClose }) => {
  const { messages: M } = useLanguage();
  const handleClose = () => onClose();

  return (
    <div className="popup-container">
      <Toast onClose={handleClose}>
        <Toast.Header>
          <strong
            className={M.config.direction === 'rtl' ? 'ms-auto' : 'me-auto'}
          >
            {msgDetails.title}
          </strong>
        </Toast.Header>
        <Toast.Body>{msgDetails.body}</Toast.Body>
      </Toast>
    </div>
  );
};

export default Popup;
