import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../l10n/LanguageContext.js';
import {
  deleteUserData,
  getConsumerDetails,
  optOutUser,
} from '../../services/profileAPI.js';
import { getCookie } from '../../services/storageUtils.js';
import Loader from './Loader';
import Popup from './Popup';

const ConsumerOptin = () => {
  const [userSelection, setUserSelection] = useState();
  const [enableButton, setEnableButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState({ title: '', body: '' });
  const UID = getCookie('UID');

  const { messages: M } = useLanguage();

  const handleChange = (event) => {
    setUserSelection(event.target.value);
  };
  const handlePopup = (show, title, body) => {
    setShowPopup(show);
    setPopupDetails({ title, body });
  };
  const performUserSelection = async () => {
    if (userSelection === 'deleteMyData') {
      setShowLoader(true);
      deleteUserData(UID)
        .then((response) => {
          setShowLoader(false);
          handlePopup(true, M.popup_title_success, M.popup_body_delete_data);
        })
        .catch((err) => {
          setShowLoader(false);
          handlePopup(true, M.popup_title_error, M.popup_body_error_generic);
        });
    } else if (userSelection === 'getMyData') {
      setShowLoader(true);
      getConsumerDetails(UID)
        .then((response) => {
          setShowLoader(false);
          handlePopup(true, M.popup_title_success, M.popup_body_export_data);
        })
        .catch((err) => {
          setShowLoader(false);
          handlePopup(true, M.popup_title_error, M.popup_body_error_generic);
        });
    } else if (userSelection === 'optOut') {
      setShowLoader(true);
      optOutUser(UID)
        .then((res) => {
          setShowLoader(false);
          handlePopup(true, M.popup_title_success, M.popup_body_opt_out);
        })
        .catch((err) => {
          setShowLoader(false);
          handlePopup(true, M.popup_title_error, M.popup_body_error_generic);
        });
    }
  };
  useEffect(() => {
    if (userSelection) setEnableButton(true);
    else setEnableButton(false);
  }, [userSelection]);

  return (
    <div className="consumer-option-container">
      <div className="consumer-options">
        <div>
          <input
            type="radio"
            value="getMyData"
            name="userAction"
            onChange={(event) => handleChange(event)}
          ></input>
          <label>{M.msg_request_data}</label>
        </div>
        <div>
          <input
            type="radio"
            value="deleteMyData"
            name="userAction"
            onChange={(event) => handleChange(event)}
          ></input>
          <label>{M.msg_delete_data}</label>
        </div>
        <div>
          <input
            type="radio"
            value="optOut"
            name="userAction"
            onChange={(event) => handleChange(event)}
          ></input>
          <label>{M.msg_opt_out}</label>
        </div>
      </div>
      <button
        className={`submit-btn ${enableButton ? 'enable-btn' : 'disable-btn'}`}
        disabled={!enableButton}
        onClick={() => performUserSelection()}
      >
        {M.cta_send_request}
      </button>
      {showLoader && <Loader />}
      {showPopup && <Popup msgDetails={popupDetails} onClose={setShowPopup} />}
    </div>
  );
};

export default ConsumerOptin;
