import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLanguage } from '../../l10n/LanguageContext';
import { sendOTP, verifyOTP } from '../../services/resetPasswordAPI';
import Loader from './Loader';
import Popup from './Popup';

const ForgotPassword = ({ onBtnClick, setPToken }) => {
  const [enableButton, setEnableButton] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [otpInput, setOtpInput] = useState(Array(6).fill(''));
  const [enableOtpButton, setEnableOtpButton] = useState(true);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [vToken, setVToken] = useState('');
  const [phnErrMsg, setPhnErrMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState({ title: '', body: '' });
  const [otpErrMsg, setOtpErrMsg] = useState('');
  const [otpCounter, setOtpCounter] = useState(0);
  const [resendLinkFlag, setResendLinkFlag] = useState(false);
  const [timerValue, setTimerValue] = useState();
  const [maxTriggerFlag, setMaxTriggerFlag] = useState(false);
  const inputRefs = useRef([]);

  const { messages: M } = useLanguage();

  const handlePopup = (show, title, body) => {
    setShowPopup(show);
    setPopupDetails({ title, body });
  };
  const updateInput = (event) => {
    if (event.target.value) {
      const PhoneNumberRegex = /^01[0125]\d{8}$/;
      if (PhoneNumberRegex.test(event.target.value)) {
        setPhoneNumber('+2' + event.target.value);
        setEnableButton(false);
        setPhnErrMsg('');
      } else {
        setEnableButton(true);
        setPhnErrMsg(M.msg_error_invalid_number);
      }
    } else {
      setEnableButton(true);
      setPhnErrMsg(M.msg_error_invalid_number);
    }
  };
  const setUserOtpInput = (userInput, index) => {
    const otpEntered = [...otpInput];
    otpEntered[index] = userInput;
    setOtpInput(otpEntered);
  };
  const maxLengthCheck = (event, index) => {
    if (event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
      setUserOtpInput(event.target.value, index);
    } else if (!event.target.value) {
      setUserOtpInput('', index);
    } else {
      setUserOtpInput(event.target.value, index);
    }
  };
  const sendOtp = async () => {
    if (otpCounter <= 2) {
      createTimer();
      setShowLoader(true);
      sendOTP(phoneNumber)
        .then((response) => {
          if (response?.['response-data']?.vToken) {
            setVToken(response?.['response-data']?.vToken);
          }
          setShowLoader(false);
          setShowOtpModal(true);
          setOtpCounter(otpCounter + 1);
        })
        .catch((err) => {
          setShowLoader(false);
          setShowOtpModal(false);
          handlePopup(true, M.popup_title_error, M.popup_body_error_send_otp);
        });
    } else {
      setMaxTriggerFlag(true);
      setTimerValue('');
      setTimeout(function () {
        setMaxTriggerFlag(false);

        setOtpCounter(0);
      }, 15 * 60 * 1000);
    }
  };
  const createTimer = () => {
    let seconds = 60;
    setResendLinkFlag(true);
    let timerInterval = setInterval(function () {
      if (seconds >= 1) {
        seconds--;

        setTimerValue(`00:${seconds}`);
      } else {
        setResendLinkFlag(false);
        clearInterval(timerInterval);
      }
    }, 1000);
  };

  const verifyOtp = async () => {
    setShowLoader(true);
    const userEnteredOtp = otpInput.join('');
    verifyOTP(userEnteredOtp, vToken, phoneNumber)
      .then((response) => {
        if (response?.responseData?.pToken) {
          setPToken(response?.responseData?.pToken);
          setShowLoader(false);
          onBtnClick();
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setOtpErrMsg(
          err?.response?.data?.errors[0]?.shortDescription ===
            'Invalid parameter value : code'
            ? M.popup_body_error_incorrect_otp
            : M.popup_body_error_verify_otp
        );
      });
  };
  const handleInputChange = (event, index) => {
    const value = event.target.value.slice(-1);
    const newOtpInput = [...otpInput];
    newOtpInput[index] = value;
    setOtpInput(newOtpInput);
    if (value && index < otpInput.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && !otpInput[index]) {
      const newOtpInput = [...otpInput];
      newOtpInput[index - 1] = '';
      setOtpInput(newOtpInput);
      inputRefs.current[index - 1].focus();
    }
  };
  const closeOtpModal = () => {
    setOtpInput(Array(6).fill(''));
    setShowOtpModal(false);
  };
  useEffect(() => {
    const otpEntered = otpInput.filter((otpInput) => otpInput !== '');
    if (otpEntered.length === 6) setEnableOtpButton(false);
    else setEnableOtpButton(true);
  }, [otpInput]);
  useEffect(() => {
    if (showOtpModal) {
      inputRefs.current[0].focus();
    }
  }, [showOtpModal]);

  return (
    <>
      <div className="forgot-password-container">
        <div className="forgot-password-form">
          <p className="forgot-password-desc">{M.msg_forgot_password_desc}</p>
          <input
            id="phoneNumber"
            type="text"
            onChange={(event) => updateInput(event)}
            placeholder={M.msg_forgot_password_hint}
          />
          <label className="error-msg">{phnErrMsg}</label>
          <button
            disabled={enableButton}
            onClick={() => {
              sendOtp();
            }}
          >
            {M.cta_continue}
          </button>
        </div>
      </div>
      {showOtpModal && (
        <div className="otp-container">
          <div
            className="modal show otp-modal"
            style={{ display: 'block', position: 'initial' }}
          >
            <Modal.Dialog>
              <Modal.Header>
                <p>{M.msg_otp_verification_title}</p>
                <button type="button" className="close" onClick={closeOtpModal}>
                  <span>&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="otp-input-container">
                  {otpInput.map((otp, index) => (
                    <input
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      key={index}
                      name={`otp${index + 1}`}
                      type="number"
                      autoComplete="off"
                      className="otp-input"
                      value={otp}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      maxLength={1}
                    />
                  ))}
                </div>
                <label className="error-msg">{otpErrMsg}</label>
                {maxTriggerFlag ? (
                  <div className="error-msg">{M.msg_otp_max_trigger}</div>
                ) : (
                  ''
                )}
                <div className="otp-timer">{timerValue}</div>
              </Modal.Body>
              <div className="btn-container">
                <button
                  className={`btn-validate ${
                    enableOtpButton ? 'disable-btn' : 'enable-btn'
                  }`}
                  disabled={enableOtpButton}
                  onClick={() => verifyOtp()}
                >
                  {M.cta_validate}
                </button>
                <button
                  className={`resend-otp ${
                    resendLinkFlag ? 'disable-link' : ''
                  }`}
                  onClick={() => sendOtp()}
                  disabled={resendLinkFlag}
                >
                  {M.cta_resend_otp}
                </button>
              </div>
            </Modal.Dialog>
          </div>
        </div>
      )}
      {showLoader && <Loader />}
      {showPopup && <Popup msgDetails={popupDetails} onClose={handlePopup} />}
    </>
  );
};

export default ForgotPassword;
