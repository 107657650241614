import axios from 'axios';
import * as apiServices from './apiService';

const API_URL_ASSISTED = process.env.REACT_APP_MULE_API_ASSISTED;
const COUNTRY_CODE = 'EG';

export const retrieveUserData = async () => {
  const params = { method: 'get', url: `` };
  return await apiServices.call(params);
};
export const getConsumerDetails = async (id) => {
  const params = {
    method: 'get',
    url: `${API_URL_ASSISTED}/v1/api/v1/consumers-details/${id}?countryCode=${COUNTRY_CODE}`,
    headers: apiServices.getHeaders(),
  };

  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
  };
  try {
    const response = await axios.get(initObj.url, {
      headers: initObj.headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUserData = async (id) => {
  const params = {
    method: 'delete',
    url: `${API_URL_ASSISTED}/v1/api/v1/consumer/${id}?countryCode=${COUNTRY_CODE}`,
    headers: apiServices.getHeaders(),
  };
  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
  };
  try {
    const response = await axios.delete(initObj.url, {
      headers: initObj.headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const optOutUser = async (id) => {
  const params = {
    method: 'patch',
    url: `${API_URL_ASSISTED}/v1/api/v1/consumer/${id}?countryCode=${COUNTRY_CODE}`,
    headers: apiServices.getHeaders(),
  };

  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
    data: {
      profile: {
        country: COUNTRY_CODE,
      },
      consents: {
        emailOptIn: false,
        smsOptIn: false,
        brandsOptin: false,
      },
    },
  };

  try {
    const response = await axios.patch(initObj.url, initObj.data, {
      headers: initObj.headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
