import Spinner from 'react-bootstrap/Spinner';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
}

export default Loader;
