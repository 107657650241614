import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Navigation from './components/common/Navigation';
import ForgotPasswordPage from './components/pages/ForgotPassword/ForgotPasswordPage';
import LegalDocuments from './components/pages/LegalDocuments/LegalDocuments';
import Login from './components/pages/Login/Login';
import Profile from './components/pages/Profile/Profile';
import { LanguageProvider } from './l10n/LanguageContext';

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Navigate to="/eg/login" replace />} />
          <Route exact path="/eg/login" element={<Login />} />
          <Route exact path="/eg/profile" element={<Profile />} />
          <Route exact path="/eg/legal" element={<LegalDocuments />} />
          <Route
            exact
            path="/eg/forgot-password"
            element={<ForgotPasswordPage />}
          />
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;
