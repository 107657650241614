const messages = {
  config: {
    code: 'en',
    label: 'English',
    label_en: 'English',
    label_ar: 'عربي',
    direction: 'ltr',
  },
  // navbar
  navbar_login: 'Login',
  navbar_profile: 'Profile',
  navbar_legal_docs: 'Legal Documents',
  navbar_forgot_password: 'Forgot Password',
  navbar_reset_password: 'Reset Password',
  navbar_logout: 'Logout',

  //login
  msg_error_invalid_username:
    'The username is invalid. Please enter a 11 digit telephone number starting with 0. example: 01067865432',
  // profile
  msg_welcome: 'Welcome',
  msg_profile_desc: 'Profile Page for data subject or deletion',
  msg_request_data: 'Request my data',
  msg_delete_data: 'Delete my data',
  msg_opt_out: 'Opt out from communication',
  // legal docs
  msg_legal_docs_desc:
    'Please Refer to the Links Below for Legal Documentations',
  msg_privacy_notice_en: 'pmi consumer privacy notice',
  msg_privacy_notice_ar: 'pmi consumer privacy notice - arabic',
  msg_tnc: 'terms and contitions - english & arabic',
  // forgot password
  msg_forgot_password_desc: 'Please enter your phone number',
  msg_forgot_password_hint: 'e.g 01xxxxxxxxx',

  msg_reset_password_desc: 'Please enter your new password',
  msg_new_password_hint: 'New password',
  msg_confirm_password_hint: 'Confirm new password',

  msg_password_constraint_1: '8 or more characters',
  msg_password_constraint_2: 'upper and lower case letter',
  msg_password_constraint_3: 'at least one number',
  msg_password_constraint_4: 'at least one special character',
  reset_pass_err_msg401:
    'The provided password was already in use by this account',
  reset_pass_err_msg403:
    'Reset password link expired, please send resetPassword again to receive new reset link',
  msg_otp_verification_title: 'Enter Verification code',
  msg_otp_max_trigger:
    'You have reached 3 times maximum attempts.Please wait 15 minutes to request new OTP.',
  // popup
  popup_title_success: 'Success',
  popup_title_error: 'Error',
  popup_body_delete_data:
    'We have received request to delete your data. All of your data will be deleted within 10 days.',
  popup_body_export_data:
    'We have received request to export your data. We are processing your request and you will receive your data shortly.',
  popup_body_opt_out:
    'We have received your opt-out request. you will be opted out within 2 days.',
  popup_body_error_generic: 'An error has occurred. please try again.',
  popup_body_error_send_otp: 'Error while sending OTP',
  popup_body_error_incorrect_otp: 'You have entered incorrect OTP',
  popup_body_error_verify_otp: 'Error while verifying OTP',
  popup_body_error_reset_password:
    'Error while resetting your password please try again after some time.',
  popup_body_success_reset_password:
    'Your password has been reset successfully.',
  msg_error_invalid_number:
    'The phone number is invalid. Please enter a 11 digit telephone number starting with 0. example: 01067865432',
  msg_error_password_not_matching: 'Passwords entered are not matching.',

  // CTA
  cta_send_request: 'Send Request',
  cta_continue: 'Continue',
  cta_confirm: 'Confirm',
  cta_validate: 'Validate',
  cta_resend_otp: 'Resend Otp',
};
export default messages;
