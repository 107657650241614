import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../l10n/LanguageContext';
import { deleteCookies, getCookie } from '../../services/storageUtils';

const Navigation = () => {
  const navigate = useNavigate();
  const { messages: M, changeLanguage, activeComponent } = useLanguage();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  };
  function logoutUser(response) {
    if (response.errorCode === 0) {
      window.gigyaAccDetails = {};
      deleteCookies(['UID', 'username']);
      navigate('/eg/login');
    }
  }
  const logout = () => {
    if (window.gigya) {
      window.gigya.accounts.logout({ callback: logoutUser });
    } else {
      deleteCookies(['UID', 'username']);
      navigate('/eg/login');
    }
  };
  useEffect(() => {
    const UID = getCookie('UID');
    setIsLoggedIn(!!UID);
  });

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>{M[`navbar_${activeComponent}`]}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={M.config.direction === 'ltr' ? 'ms-auto' : 'me-auto'}>
            {!isLoggedIn && (
              <>
                <Nav.Link as={Link} to="/eg/login">
                  {M.navbar_login}
                </Nav.Link>
                <Nav.Link as={Link} to="/eg/forgot-password">
                  {M.navbar_forgot_password}
                </Nav.Link>
              </>
            )}
            {isLoggedIn && (
              <>
                <Nav.Link as={Link} to="/eg/profile">
                  {M.navbar_profile}
                </Nav.Link>
                <Nav.Link as={Link} to="/eg/legal">
                  {M.navbar_legal_docs}
                </Nav.Link>
                <Nav.Link onClick={() => logout()}>{M.navbar_logout}</Nav.Link>
              </>
            )}
            <NavDropdown title={M.config.label} id="language-dropdown">
              <NavDropdown.Item onClick={() => handleLanguageChange('en')}>
                {M.config.label_en}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleLanguageChange('ar')}>
                {M.config.label_ar}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
