import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../l10n/LanguageContext';
import { getCookie } from '../../../services/storageUtils';

const LegalDocuments = () => {
  const navigate = useNavigate();
  const { messages: M, setActiveComponent } = useLanguage();

  useEffect(() => {
    setActiveComponent('legal_docs');
    if (!getCookie('UID')) {
      navigate('/eg/login');
    }
  });

  return (
    <div className="legal-docs-container">
      <p className="legal-docs-desc">{M.msg_legal_docs_desc}</p>
      <div className="links-container">
        <a
          href="/assets/Consumer_Privacy_Notice_13.38_EG_EN_12.12.23.pdf"
          target="_blank"
        >
          {M.msg_privacy_notice_en}
        </a>
        <a
          href="/assets/Consumer_Privacy_Notice_13.38_EG_AR_12.12.2023.pdf"
          target="_blank"
        >
          {M.msg_privacy_notice_ar}
        </a>
        <a href="/assets/EG_TnCs_EN_AR.pdf" target="_blank">
          {M.msg_tnc}
        </a>
      </div>
    </div>
  );
};

export default LegalDocuments;
