import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../l10n/LanguageContext';
import { resetPassword } from '../../services/resetPasswordAPI';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import Popup from './Popup';

const ResetPassword = (props) => {
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [enableButton, setEnableButton] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState({ title: '', msg: '' });
  const { messages: M } = useLanguage();
  const navigate = useNavigate();
  const handleInput = (event, type) => {
    if (type === 'newPassword') {
      if (event.target.value) {
        setNewPassword(event.target.value);
      } else {
        setNewPassword();
      }
    } else {
      if (event.target.value) {
        setConfirmPassword(event.target.value);
      } else {
        setConfirmPassword();
      }
    }
  };
  const handlePopup = (popupstate, title, body) => {
    setShowPopup(popupstate);
    setPopupDetails({ title, body });
  };
  const resetUserPassword = async () => {
    setShowLoader(true);
    resetPassword(props.pToken, confirmPassword)
      .then((response) => {
        setShowLoader(false);
        handlePopup(
          true,
          M.popup_title_success,
          M.popup_body_success_reset_password
        );
        navigate('/eg/login');
      })
      .catch((err) => {
        let errorCode = err?.response?.data?.errors?.[0]?.errorCode;
        let errorMsg =
          errorCode === 401 || errorCode === 403
            ? M[`reset_pass_err_msg${errorCode}`]
            : M.popup_body_error_reset_password;
        setShowLoader(false);
        handlePopup(true, M.popup_title_error, errorMsg);
      });
  };
  const handleClick = () => {
    if (newPassword !== confirmPassword) {
      setErrorMsg(M.msg_error_password_not_matching);
    } else {
      setErrorMsg('');
      resetUserPassword();
    }
  };
  useEffect(() => {
    if (newPassword && confirmPassword) setEnableButton(false);
    else setEnableButton(true);
  }, [newPassword, confirmPassword]);

  return (
    <div className="reset-password-container">
      <h3 className="reset-password-desc">{M.msg_reset_password_desc}</h3>
      <div className="reset-password-form">
        <input
          id="newPassword"
          type="password"
          placeholder={M.msg_new_password_hint}
          onChange={(event) => {
            handleInput(event, 'newPassword');
          }}
        />
        <input
          id="confirmPassword"
          type="password"
          placeholder={M.msg_confirm_password_hint}
          onChange={(event) => {
            handleInput(event, 'confirmPassword');
          }}
        />
        {errorMsg ? <div className="error-msg">{errorMsg}</div> : <div></div>}
        <div className="password-constraints-container">
          <ul>
            <li>{M.msg_password_constraint_1}</li>
            <li>{M.msg_password_constraint_2}</li>
            <li>{M.msg_password_constraint_3}</li>
            <li>{M.msg_password_constraint_4}</li>
          </ul>
        </div>
        <button
          className="btn-confirm"
          disabled={enableButton}
          onClick={() => handleClick()}
        >
          {M.cta_confirm}
        </button>
      </div>
      {showLoader && <Loader />}
      {showPopup && <Popup msgDetails={popupDetails} onClose={handlePopup} />}
    </div>
  );
};

export default ResetPassword;
