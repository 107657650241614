const messages = {
  config: {
    code: 'ar',
    label: 'عربي',
    label_en: 'English',
    label_ar: 'عربي',
    direction: 'rtl',
  },
  // navbar
  navbar_login: 'تسجيل الدخول',
  navbar_profile: 'حساب',
  navbar_legal_docs: 'وثائق قانونية',
  navbar_forgot_password: 'هل نسيت كلمة السر',
  navbar_reset_password: 'إعادة تعيين كلمة المرور',
  navbar_logout: 'تسجيل خروج',

  //login
  msg_error_invalid_username:
    'اسم المستخدم غير صالح. برجاء إدخال رقم هاتف مكون من 11 رقم يبدأ بـ 0. مثال: 01067865432',
  // profile
  msg_welcome: 'مرحباً',
  msg_profile_desc: 'صفحة الملف الشخصي لموضوع البيانات أو الحذف',
  msg_request_data: 'اطلب بياناتي',
  msg_delete_data: 'حذف بياناتي',
  msg_opt_out: 'إلغاء الاشتراك في التواصل ',
  // legal docs
  msg_legal_docs_desc:
    'يرجى الرجوع إلى الروابط أدناه للحصول على الوثائق القانونية',
  msg_privacy_notice_en: 'إشعار خصوصية المستهلك PMI',
  msg_privacy_notice_ar: 'إشعار خصوصية المستهلك PMI - عربي',
  msg_tnc: 'الشروط والأحكام - الإنجليزية والعربية',
  // forgot password
  msg_forgot_password_desc: 'يرجى إدخال رقم الهاتف الخاص بك',
  msg_forgot_password_hint: 'على سبيل المثال 01xxxxxxxxx',

  msg_reset_password_desc: 'الرجاء إدخال كلمة المرور الجديدة',
  msg_new_password_hint: 'كلمة المرور الجديدة',
  msg_confirm_password_hint: 'تأكيد كلمة المرور الجديدة',

  msg_password_constraint_1: '8 أحرف أو أكثر',
  msg_password_constraint_2: 'الأحرف الكبيرة و الصغيرة',
  msg_password_constraint_3: 'رقم واحد على الأقل',
  msg_password_constraint_4: 'حرف خاص واحد على الأقل',
  reset_pass_err_msg401:
    'كلمة المرور المقدمة كانت قيد الاستخدام بالفعل بواسطة هذا الحساب',
  reset_pass_err_msg403:
    'انتهت صلاحية رابط إعادة تعيين كلمة المرور، يرجى إرسال إعادة تعيين كلمة المرور مرة أخرى لتلقي رابط إعادة تعيين جديد',
  msg_otp_verification_title: 'أدخل رمز التحقق',
  msg_otp_max_trigger:
    'لقد وصلت إلى 3 مرات الحد الأقصى للمحاولات. يرجى الانتظار لمدة 15 دقيقة لطلب كلمة المرور لمرة واحدة (OTP) جديدة.',
  // popup
  popup_title_success: 'نجاح',
  popup_title_error: 'خطأ',
  popup_body_delete_data:
    'لقد تلقينا طلبًا لحذف بياناتك. سيتم حذف جميع بياناتك خلال 10 أيام.',
  popup_body_export_data:
    'لقد تلقينا طلبًا لتصدير بياناتك. نحن نقوم بمعالجة طلبك وسوف تتلقى البيانات الخاصة بك قريبا.',
  popup_body_opt_out:
    'لقد تلقينا طلب إلغاء الاشتراك الخاص بك. سيتم إلغاء الاشتراك خلال يومين.',
  popup_body_error_generic: 'حدث خطأ. حاول مرة اخرى.',
  popup_body_error_send_otp: 'خطأ أثناء إرسال OTP',
  popup_body_error_incorrect_otp:
    'لقد قمت بإدخال كلمة مرور لمرة واحدة (OTP) غير صحيحة',
  popup_body_error_verify_otp: 'حدث خطأ أثناء التحقق من OTP',
  popup_body_error_reset_password:
    'حدث خطأ أثناء إعادة تعيين كلمة المرور الخاصة بك، يرجى المحاولة مرة أخرى بعد مرور بعض الوقت.',
  popup_body_success_reset_password:
    'تم إعادة تعيين كلمة المرور الخاصة بك بنجاح.',
  msg_error_invalid_number:
    'رقم الهاتف غير صالح. الرجاء إدخال رقم الهاتف المكون من 11 رقمًا ويبدأ بالرقم 0. مثال: 01067865432',
  msg_error_password_not_matching: 'كلمات المرور المدخلة غير متطابقة.',

  // CTA
  cta_send_request: 'ارسل طلب',
  cta_continue: 'يكمل',
  cta_confirm: 'يتأكد',
  cta_validate: 'التحقق من صحة',
  cta_resend_otp: 'إعادة إرسال كلمة المرور',
};
export default messages;
