import axios from 'axios';
import { v1 as uuid } from 'uuid';

export const getHeaders = () => {
  const header = {
    'X-Correlation-Id': uuid(),
    'X-Channel': 'WEBSITE',
    Authorization: window?.gigyaAccDetails?.auth_token
      ? `Bearer ${window.gigyaAccDetails.auth_token} `
      : '',
  };
  return header;
};
export const getUnbrandedHeaders = () => {
  const header = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    'X-Channel': 'WEBSITE',
    'X-Correlation-Id': uuid(),
  };
  return header;
};

export const call = async (params) => {
  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
  };
  if (params.body) {
    initObj.data = params.body;
  }

  if (initObj.method === 'get') {
    const queryParams = new URLSearchParams(params.body);
    initObj.url = `${initObj.url}?${queryParams}`;
    delete initObj.data;
  }

  return await axios(initObj)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
