import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../l10n/LanguageContext';
import { getCookie, setCookie } from '../../../services/storageUtils';

const Login = () => {
  const gigyaKey = process.env.REACT_APP_GIGYA_KEY;

  let EG_PHONENUMBER_REGEX = /^01[0125]\d{8}$/;
  const navigate = useNavigate();
  const { language, messages: M, setActiveComponent } = useLanguage();

  const updateAuthToken = (tokenData) => {
    if (tokenData?.id_token && window?.gigyaAccDetails) {
      window.gigyaAccDetails['auth_token'] = tokenData['id_token'];
    }
  };

  const toggleLoginIdError = () => {
    const loginScreensetContainer = document.querySelector(
      '#loginScreensetContainer'
    );
    const loginIdContainer = loginScreensetContainer.querySelector(
      '.gigya-composite-control-textbox'
    );
    const loginIdInput = loginIdContainer.querySelector('input');
    const loginIdErrorContainer = document.createElement('span');
    loginIdErrorContainer.id = 'loginIdCustomError';
    loginIdErrorContainer.className = 'gigya-error-msg';
    loginIdErrorContainer.innerText = M.msg_error_invalid_username;
    loginIdContainer.appendChild(loginIdErrorContainer);
    loginIdInput.addEventListener('change', (e) => {
      const { value } = e.target;
      const isPhone = EG_PHONENUMBER_REGEX.test(value);
      if (
        (isPhone &&
          loginIdErrorContainer.classList.contains('gigya-error-msg-active')) ||
        value === ''
      ) {
        loginIdErrorContainer.classList.remove('gigya-error-msg-active');
      } else if (
        (isPhone &&
          !loginIdErrorContainer.classList.contains(
            'gigya-error-msg-active'
          )) ||
        value === ''
      ) {
        return;
      } else {
        loginIdErrorContainer.classList.add('gigya-error-msg-active');
      }
    });
  };
  useEffect(() => {
    if (getCookie('UID')) {
      navigate('/eg/profile');
    }
    setActiveComponent('login');
    const gigyaWebSDK = document.createElement('script');
    gigyaWebSDK.type = 'text/javascript';
    gigyaWebSDK.lang = 'javascript';
    gigyaWebSDK.src = `https://cdns.gigya.com/js/gigya.js?apikey=${gigyaKey}`;

    document.head.appendChild(gigyaWebSDK);
    gigyaWebSDK.addEventListener('load', () => {
      const gigya = window.gigya;
      if (gigya) {
        gigya.accounts.showScreenSet({
          screenSet: 'Marlboro-RegistrationLogin',
          startScreen: 'marlboro-login-screen',
          containerID: 'loginScreensetContainer',
          lang: language,
          onSubmit: handleSubmit,
          onAfterScreenLoad: handleScreenLoad,
        });
        gigya.accounts.addEventHandlers({
          onLogin: checkLoginStatus,
        });
        function checkLoginStatus(account) {
          if (account) {
            window.gigyaAccDetails = account;
            setCookie('UID', account.UID);
            setCookie('username', account.profile.firstName.trim());
            gigya.accounts.getJWT({
              callback: updateAuthToken,
              expiration: 3600,
            });
            navigate('/eg/profile');
          }
        }
        function handleSubmit(response) {
          if (response?.screen === 'marlboro-login-screen') {
            const loginID = response?.formModel?.loginID;
            const loginId = `+2${loginID}`;
            response.formModel.loginID = loginId;
          }
        }
        function handleScreenLoad(event) {
          if (event.currentScreen === 'marlboro-login-screen') {
            toggleLoginIdError();
          }
        }
      }
    });
  });

  return (
    <div className="login-page">
      <h1 className="login-page-heading">{M.msg_welcome}</h1>
      <div id="loginScreensetContainer"></div>
    </div>
  );
};

export default Login;
