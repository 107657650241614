import axios from 'axios';
import { getUnbrandedHeaders } from '../services/apiService';

const API_URL_ASSISTED = process.env.REACT_APP_MULE_API_ASSISTED;
const API_URL_UTILITY = process.env.REACT_APP_MULE_API_UTILITY;
const gigyaKey = process.env.REACT_APP_GIGYA_KEY;
const COUNTRY_CODE = 'EG';

export const resetPassword = async (pToken, newPassword) => {
  const params = {
    method: 'POST',
    headers: getUnbrandedHeaders(),
  };
  params.url = `${API_URL_ASSISTED}/v1/api/v1/consumer/password/reset`;
  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
    data: {
      passwordResetToken: pToken,
      apiKey: gigyaKey,
      countryCode: COUNTRY_CODE,
      password: newPassword,
    },
  };
  try {
    const response = await axios.post(initObj.url, initObj.data, {
      headers: initObj.headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendOTP = async (username) => {
  const params = {
    method: 'POST',
    url: `${API_URL_ASSISTED}/v1/api/v1/consumer/password/recovery`,
    headers: getUnbrandedHeaders(),
  };
  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
    data: {
      username: username,
      countryCode: COUNTRY_CODE,
      lang: 'ar',
    },
  };
  try {
    const response = await axios.post(initObj.url, initObj.data, {
      headers: initObj.headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOTP = async (otp, vToken, phoneNumber) => {
  const params = {
    method: 'POST',
    url: `${API_URL_UTILITY}/v1/api/v1/password-tokens?countryCode=${COUNTRY_CODE}`,
    headers: getUnbrandedHeaders(),
  };
  const initObj = {
    method: params.method,
    headers: params.headers,
    url: params.url,
    data: {
      code: otp,
      vToken: vToken,
      username: phoneNumber,
      countryCode: COUNTRY_CODE,
    },
  };
  try {
    const response = await axios.post(initObj.url, initObj.data, {
      headers: initObj.headers,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
